<script setup lang="ts">
    import { LazyCmsGoogleFormWidgetContainer, LazyOrgKlaviyoBackInStock } from '#components';
    import type { LocationQueryRaw } from '#vue-router';
    import type { GoogleFormWidgetData } from '~/@types/cms';
    import type { PageProps } from '~/@types/generic';
    import type { Product } from '~/graphql/generated';

    const props = defineProps<PageProps>();

    const nuxtApp = useNuxtApp();
    const appStore = useAppStore();
    const route = useRoute();
    const localPath = useLocalePathWoom();
    const { getConfiguratorId, processProduct } = useProduct();
    const { page, pageId, breadcrumbItems, isBike, bundles, upsellData, pageState } = usePage(props);
    const { locale, t, isoCode, iso2Country, isUS } = useLocales();
    const { addRecommended, addToCart } = useCart();
    const { bikeFinderLocaleUrl, handleBikeFinder } = useBikeFinder();
    const { enabled } = usePreviewMode();

    const product = computed(() => processProduct(page.value?.product as Partial<Product> | undefined));
    const { config, cartBusy } = useProductCard(product.value, {
        preselectSku: route.query.sku,
        variantUrl: route.query.variant,
    });

    let currentVariantId = '';

    const variant = computed(() => {
        if (!config) return undefined;

        const variant = config.selected.value;

        // FIXME: check for currentVariantId because the computed variant runs again after navigation 🤷🏼‍♀️
        if (import.meta.client && variant && currentVariantId !== variant.id) {
            currentVariantId = variant.id;
            const query: LocationQueryRaw = {
                ...route.query,
                variant: config.variantUrlQuery.value,
            };
            if (enabled.value) query.preview = 'true';

            navigateTo(
                {
                    query,
                    hash: route.hash,
                },
                {
                    replace: true,
                },
            );

            nuxtApp.$webgains.pushEvent({
                pageType: 'product',
                productId: variant.sku,
                productPrice: variant.price,
                productName: product.value?.title,
                categoryId: product.value?.category?.[0],
            });
        }
        if (!config.isDigital) useProductMicrodata(variant, page.value?.product?.description);
        return variant;
    });

    /**
     * render the toggle between two products
     */
    const toggle = computed(() => {
        if (!page.value?.content?.toggle) return;

        const toggle = page.value?.content?.toggle;
        const toggleData = typeof toggle === 'string' ? appStore.getReference(toggle) : toggle;

        if (!toggleData) return;

        const urlLeft = getLink(toggleData.left.link);
        const urlRight = getLink(toggleData.right.link);
        const currentPath = route.path;

        if (!urlLeft || !urlRight || !currentPath) return undefined;

        // Remove trailing slashes from currentPath, urlLeft, and urlRight and localize
        const currentPathWithoutTrailingSlash = currentPath.replace(/\/$/, '');
        const urlLeftClean = localPath(urlLeft.replace(/\/$/, ''));
        const urlRightClean = localPath(urlRight.replace(/\/$/, ''));

        return {
            left: {
                label: woomTextFormat(toggleData.left.label),
                url: urlLeft,
                // selected: route.path.includes(ul),
                selected: currentPathWithoutTrailingSlash === urlLeftClean,
            },
            right: {
                label: woomTextFormat(toggleData.right.label),
                url: urlRight,
                // selected: route.path.includes(ur),
                selected: currentPathWithoutTrailingSlash === urlRightClean,
            },
        };
    });

    const hasTopControllerContent = computed(() => {
        if (page.value && page.value?.content && page.value?.content.value) {
            const v = page.value?.content.value.value;
            return v && v !== '' && v !== '<p><br></p>';
        }
        return false;
    });

    const currentVariant = computed(() => variant.value?.sku ?? '');

    /**
     * the old gallery data for the pdp - still in use as fallback and for accessory, spare parts and apparel
     */
    const variantImages = computed(() => {
        if (currentVariant.value) {
            const v = page.value?.product?.variants?.items?.find((p: any) => p.sku === currentVariant.value);

            if (v?.content?.gallery) {
                // console.log('got variant gallery', v?.sku, v?.content?.gallery);
                return v?.content.gallery;
            }
            if (page.value?.content?.gallery) {
                /**
                 * filter the content gallery by color tokens (if there are any)
                 */
                // console.log('got page gallery', page.value.content.gallery);
                /*
              const options = (this.variant?.selectedOptions ?? []).filter((option) => option.name.toLowerCase() === 'color')
              if (options.length) {
                const color = options[0].value.replace('woom', '').trim().split(' ')
                const images = this.content.content.gallery.filter(( image ) => {
                  if (image.data['1to1']) {
                    for (let colorPart of color) {
                      if (image.data['1to1'].includes(colorPart)) {
                        return image
                      }
                    }
                  }
                })
                if (images.length) {
                  return images
                }
              }
              */
                return page.value.content.gallery;
            }
            if (page.value?.product?.hero) {
                return [page.value.product.hero];
            }
            return [
                {
                    id: null,
                    src: v?.shopImage,
                    data: null,
                },
            ];
        } else {
            // console.log('get page content gallery - no variant');
            if (page.value?.content?.gallery) {
                return page.value.content.gallery;
            }
            if (page.value?.product?.hero) {
                return [page.value.product.hero];
            }
            return [
                {
                    id: null,
                    src: null,
                    data: null,
                },
            ];
        }
    });

    const validationError = computed(() => {
        if (config?.validationError.value && config?.validationError.value !== '') {
            return t(config.validationError.value);
        }
        return undefined;
    });

    const gallery = useUspGallery(currentVariant, page.value?.product?.usp);

    const giftCardImage = computed(() => {
        if (!config?.isGiftCard || !currentVariant.value) return;
        return getAssetUrl(`${currentVariant.value}`);
    });

    const getLink = (link?: Link) => {
        if (link?.type === 'sitemap' || link?.type === 'page') {
            const l = appStore.getReference(link.data, 'links');
            if (l?.url?.url) {
                return l.url.url as string;
            }
        }
        return undefined;
    };

    /**
     * we open a notify me with a google form in EU and klaviyo form on US side
     */
    const notifyMe = () => {
        if (isUS.value) {
            /**
             * @deprecated
             * klaviyo will be replaced by google forms
             * TODO: remove this after klaviyo is removed
             */
            console.log('notify me US');
            nuxtApp.$eventEmitter.emit('side-drawer', {
                title: t('cart.tellMeFirst'),
                content: {
                    component: LazyOrgKlaviyoBackInStock,
                    props: {
                        variant: variant.value,
                    },
                },
            });
        } else if (appStore.website?.shop?.notify_me2) {
            nuxtApp.$eventEmitter.emit('side-drawer', {
                title: t('cart.tellMeFirst'),
                content: {
                    component: LazyCmsGoogleFormWidgetContainer,
                    props: {
                        data: {
                            codename: '',
                            id: '',
                            language: '',
                            form: appStore.website.shop.notify_me2,
                            selectedProductId: variant.value?.barcode,
                            types: [],
                            background: 'white',
                            fullWidth: true,
                            success: appStore.website.shop.notify_me2_success,
                        },
                    } as IWidgetProps<GoogleFormWidgetData>,
                },
            });
        }
    };
    const availability = computed(() => {
        if (!variant.value || config?.isDigital) return;
        if (variant.value?.quantityAvailable < 1) return 'soldOut';
        if (variant.value.quantityAvailable <= ProductQuantityAvailable.LOW_STOCK) return 'lowStock';
        return 'inStock';
    });

    onMounted(async () => {
        if (product.value) {
            const id = getConfiguratorId(product.value);

            try {
                const { promise: awaitable } = useCollectionQueryData({
                    variables: {
                        id,
                    },
                });

                const data = await awaitable;

                if (data != null) {
                    pageState.value.configurator = data.data.collection?.products?.items;
                }
            } catch (err) {}
        }

        if (bundles.value.length > 0) {
            try {
                const data = await useBundlesQueryData({
                    variables: {
                        ids: bundles.value,
                        locale: locale.value,
                        preview: enabled.value,
                    },
                }).promise;

                const bs = data?.data.bundles || [];
                if (Array.isArray(bs) && bs.length > 0) {
                    const r: BundlesQuery_Bundle[] = [];
                    for (const b of bs) {
                        if (b.availableForSale) {
                            r.push(b);
                        }
                    }
                    pageState.value.bundleData = r;
                    // console.log('got bundle data', this.bundleData)
                }
            } catch (err) {}
        }

        if (upsellData.value && upsellData.value.length > 0 && product.value) {
            addRecommended(product.value.id, upsellData.value);
        }

        if (nuxtApp.$gtm) {
            await wait(20);
            nuxtApp.$gtm.ecommerceProductPage(page.value);
        }

        if (isUS && product?.value) {
            const productUrl = location.href;
            stampedInitRichSnippets(product.value.title, productUrl);
        }
    });

    onUnmounted(async () => {
        stampedUnloadRichSnippets();
    });
</script>
<template>
    <div :class="[{ 'lg:min-h-screen-desktop': gallery.length > 0 }]">
        <atm-grid
            full-width
            class="border-grey-mid mb-8 border-b pb-8">
            <atm-breadcrumbs
                :items="breadcrumbItems"
                class="col-span-2 md:col-span-12" />

            <lazy-atm-heading
                v-if="page?.product?.title"
                level="h1"
                size="md"
                class="col-span-2 md:col-span-12 lg:sr-only">
                <span v-html="woomTextFormat(page.product.title)"></span>
            </lazy-atm-heading>

            <lazy-mol-gallery
                v-if="gallery.length > 0"
                :data="gallery"
                class="col-span-2 md:col-span-12 lg:col-span-8">
                <mol-product-labels
                    v-if="product && variant"
                    :labels="product.labels"
                    :variant-labels="variant.labels"
                    class="left-0" />
            </lazy-mol-gallery>
            <lazy-atm-image
                v-else-if="giftCardImage"
                :src="giftCardImage"
                sizes="100vw md:1024px lg:1264px"
                :modifiers="{ format: 'webp' }"
                alt="gift card product image"
                class="col-span-2 md:col-span-12 lg:col-span-8" />
            <lazy-mol-product-image
                v-else
                :images="variantImages"
                :is-bike="isBike"
                class="max-md:-mx-4 md:max-lg:-mx-6">
                <mol-product-labels
                    v-if="product && variant"
                    :labels="product.labels"
                    :variant-labels="variant.labels" />
            </lazy-mol-product-image>

            <div class="col-span-2 flex flex-col gap-6 max-md:pb-6 md:col-span-12 lg:col-span-4">
                <lazy-atm-heading
                    v-if="page?.product?.title"
                    level="span"
                    size="md"
                    class="max-lg:hidden">
                    <span v-html="woomTextFormat(page.product.title)"></span>
                </lazy-atm-heading>

                <atm-stamped-stars :data-id="product?.productId" />

                <div
                    v-if="page?.product?.description"
                    v-html="woomTextFormat(page.product.description)"></div>

                <div
                    v-if="availability && $t(`availability.${availability}.short`)"
                    class="font-mono">
                    <span :class="['availability', availability]">
                        {{ $t(`availability.${availability}.short`) }}
                    </span>
                    {{ $t(`availability.${availability}.long`) }}
                </div>

                <lazy-mol-product-toggle
                    v-if="toggle"
                    :left="toggle.left"
                    :right="toggle.right" />

                <lazy-org-product-options
                    v-if="config?.options"
                    :config="config"
                    :isPDP="true" />
                <mol-product-price
                    :price="variant?.price"
                    :compare-at-price="variant?.compareAtPrice"
                    :product-price-label="page?.content?.productPriceLabel"
                    show-border
                    alignment="right"
                    stacked
                    size="lg" />
                <lazy-atm-affirm
                    v-if="isUS && variant?.canBeOrdered && variant.priceNumeric > 0"
                    :amount="variant.priceNumeric" />
                <lazy-atm-klarna
                    v-else-if="!isUS && variant?.canBeOrdered && variant.priceNumeric > 0"
                    :amount="variant.priceNumeric"
                    :region="iso2Country"
                    :iso-code="isoCode" />

                <!-- add to cart button with logic -->
                <div
                    v-if="validationError"
                    class="rounded bg-woom-grey p-4 text-center text-woom-red">
                    {{ $t(validationError) }}
                </div>
                <lazy-atm-button
                    v-if="
                        config &&
                        (variant?.availabilityState === ProductAvailability.PREORDER || variant?.availabilityState === ProductAvailability.AVAILABLE)
                    "
                    block
                    icon-right
                    :loading="cartBusy"
                    :disabled="!unref(config.valid)"
                    @click="addToCart([config?.current])">
                    {{ variant?.availabilityState === ProductAvailability.PREORDER ? $t('cta.cart.addPreorder') : $t('cta.cart.add') }}
                    <template #icon>
                        <woom-icon-add-to-cart />
                    </template>
                </lazy-atm-button>
                <div
                    v-else-if="config && variant?.availabilityState === ProductAvailability.NOTIFY_ME"
                    class="flex flex-col gap-4 rounded-md bg-woom-grey p-4 text-center">
                    <span class="px-4 text-center">{{ $t('availability.notifyMe') }}</span>
                    <lazy-atm-button
                        block
                        styling="solid-secondary"
                        @click="notifyMe">
                        {{ $t('cart.tellMeFirst') }}
                    </lazy-atm-button>
                </div>
                <div
                    v-else
                    class="w-full rounded bg-woom-grey p-4 text-center">
                    {{ variant?.availabilityInfo }}
                </div>

                <!-- payment icon display -->
                <atm-shop-icons icon-type="payment" />

                <!-- product options -->
                <div
                    v-if="isBike"
                    class="flex flex-col gap-4 rounded bg-woom-grey p-4 font-mono text-xs">
                    <div class="flex items-center gap-4">
                        <woom-icon-for-the-bike class="h-6 w-6 shrink-0" />
                        <div class="flex shrink grow flex-col gap-2">
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.wheels"
                                :value="product?.bikeDetail?.wheels.value"
                                :label="product?.bikeDetail?.wheels.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.weight"
                                :value="product?.bikeDetail?.weight.value"
                                :label="product?.bikeDetail?.weight.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.features"
                                :value="product?.bikeDetail?.features.value"
                                :label="product?.bikeDetail?.features.label" />
                        </div>
                    </div>
                    <hr class="border-woom-grey-mid" />
                    <div class="flex items-center gap-4">
                        <woom-icon-for-the-kid class="h-6 w-6 shrink-0" />
                        <div class="flex shrink grow flex-col gap-2">
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.age"
                                :value="product?.bikeDetail?.age.value"
                                :label="product?.bikeDetail?.age.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.size"
                                :value="product?.bikeDetail?.size.value"
                                :label="product?.bikeDetail?.size.label" />
                        </div>
                    </div>
                    <template v-if="bikeFinderLocaleUrl">
                        <hr class="border-woom-grey-mid" />
                        <div class="flex items-center gap-4">
                            <nuxt-link
                                :to="bikeFinderLocaleUrl"
                                @click="handleBikeFinder"
                                class="flex cursor-pointer items-center gap-4 underline transition-colors hover:text-woom-red">
                                <woom-icon-size-guide class="h-6 w-6 shrink-0 fill-current"></woom-icon-size-guide>
                                {{ $t('bikeFinder.label') }}
                            </nuxt-link>
                        </div>
                    </template>
                </div>
            </div>
        </atm-grid>

        <!-- render the generic content -->
        <cms-content-controller
            v-if="hasTopControllerContent"
            :data="page?.content.value"
            :page-id="pageId" />

        <lazy-mol-stamped-main-widget
            v-if="isUS"
            :product-id="product?.productId"
            :shop-title="page?.product?.shopTitle"
            :image-url="gallery[0]?.src"
            :description="page?.product?.description"
            :product-sku="page?.product?.handle"
            :product-type="product?.productType" />
    </div>
</template>
<style scoped>
    .availability::before {
        @apply align-middle text-3xl leading-3 content-['\2022'];
    }
    .inStock {
        @apply text-woom-green;
    }
    .soldOut {
        @apply text-woom-red;
    }
    .lowStock {
        @apply text-woom-orange-flame;
    }
</style>
